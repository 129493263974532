export const gaugesFunc = (token: string) => {
  var t = document.createElement("script")
  t.type = "text/javascript"
  t.async = true
  t.id = "gauges-tracker"
  t.setAttribute("data-site-id", token)
  t.src = "https://secure.gaug.es/track.js"
  var s = document.getElementsByTagName("script")[0]
  s.parentNode?.insertBefore(t, s)
}
