import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { json, type LinksFunction, type LoaderFunctionArgs } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  useLoaderData,
  useLocation,
  useRouteError } from
"@remix-run/react";
import stylesheet from "~/styles/globals.css";
import clsx from "clsx";
import { Theme, ThemeProvider, useTheme } from "remix-themes";
import { themeSessionResolver } from "~/utils/session.server";
import { Public } from "./utils/remix";
import defaultAvatar from "~/assets/default-avatar.webp";
import { getImage } from "~/utils/image.server";
import { cn } from "~/utils/shadecn";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { gaugesFunc } from "~/utils/trackers/gauges";
import favicon from "~/assets/favicon.webp";
import oops from "~/assets/oops.webp";
import Nav from "~/components/nav";
import Typography from "~/components/typography";
import Footer from "~/components/footer";
import gtag from "~/utils/trackers/gtag";
import { useCookies } from "react-cookie";
import { dotCart, dotIdentify, dotTrack } from "~/utils/trackers/dot";
import { CreditState } from "./purchases/credits.server";
import dayjs from "dayjs";
import { GetBundlePointCount, GetSubscriptionPointCount } from "./utils/legacy-credits.server";
import { UserFeed } from "~/models/users.server";
import { PurchaseState } from "~/purchases/states";
import { Purchases } from "@prisma/client";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: stylesheet }];

export const loader = Public(async ({ request, context, params }: LoaderFunctionArgs) => {
  const { getTheme } = await themeSessionResolver(request);

  let avatar: string | undefined = undefined;
  let sub: Purchases | undefined = undefined;
  let newNotifications = [];
  let subCreditCount = 0;
  let bundleCreditCount = 0;
  if (context.user) {
    avatar = getImage(context.user.Avatar, defaultAvatar);
    newNotifications = await UserFeed(
      context,
      11,
      context.user.LastNotificationSeen || context.user.CreatedAt || new Date(),
      true
    );

    sub = await context.db.purchases.findFirst({
      where: {
        UserID: context.user.UserID,
        State: { not: { in: [PurchaseState.Init, PurchaseState.Cancelled] } },
        Package: {
          CreditsToAward: { gt: 0 },
          RecurInterval: { not: null }
        }
      },
      include: { Package: true }
    });

    const subCredits = await context.db.credits.aggregate({
      where: {
        UserID: context.user.UserID,
        State: CreditState.Available,
        ExpiresAt: { gt: dayjs().toDate() },
        Package: {
          RecurInterval: { not: null }
        }
      },
      _count: true
    });
    const legacySubscriptionCredits = await GetSubscriptionPointCount(context);

    const bundleCredits = await context.db.credits.aggregate({
      where: {
        UserID: context.user.UserID,
        State: CreditState.Available,
        ExpiresAt: { gt: dayjs().toDate() },
        Package: {
          RecurInterval: null
        }
      },
      _count: true
    });
    const legacyBundleCredits = await GetBundlePointCount(context);

    subCreditCount = subCredits._count + legacySubscriptionCredits;
    bundleCreditCount = bundleCredits._count + legacyBundleCredits;
  }

  return json({
    env: process.env.MLNP_ENV,
    user: context.user,
    sub: sub,
    masquerador: context.masquerador,
    avatar: avatar,
    notifications: newNotifications.length,
    subCredits: subCreditCount,
    bundleCredits: bundleCreditCount,
    theme: getTheme(),
    version: process.env.COMMIT_SHA?.slice(0, 7),
    gaugesToken: process.env.GAUGES_SITE_ID,
    intercomToken: process.env.INTERCOM_APP_ID,
    gaTrackingID: process.env.GA_TRACKING_ID,
    gTagID: process.env.GOOGLE_TAGMANAGER_ID
  });
});

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();

  return (
    <ThemeProvider specifiedTheme={data.theme} themeAction="/set-theme">
      <App />
    </ThemeProvider>);

}

export default withSentry(AppWithProviders);

export const shouldRevalidate: ShouldRevalidateFunction = (args) => {
  if (args.actionResult?.revalidate === false) {
    return false;
  }
  return args.defaultShouldRevalidate;
};

export function App() {
  const data = useLoaderData<typeof loader>();
  const location = useLocation();
  const [theme] = useTheme();
  const [dotLoaded, setDotLoaded] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies<string>(["cart"]);

  if (data.env === "production") {
    useEffect(() => {
      const gtm = document.createElement("script");
      gtm.src = `https://www.googletagmanager.com/gtm.js?id=${data.gTagID}`;
      gtm.async = true;
      document.body.appendChild(gtm);

      const gtmInit = document.createElement("script");
      gtmInit.async = true;
      gtmInit.id = "gtag-init";
      gtmInit.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${data.gTagID}');
      `;
      document.body.appendChild(gtmInit);

      return () => {
        try {
          document.body.removeChild(gtm);
          document.body.removeChild(gtmInit);
        } catch (e) {}
      };
    }, [data.gaTrackingID]);

    useEffect(() => {
      const script = document.createElement("script");
      script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + '${data.intercomToken}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
      document.head.appendChild(script);

      return () => {
        try {
          document.head.removeChild(script);
        } catch (e) {}
      };
    }, [data.intercomToken, data.user]);

    useEffect(() => {
      const script = document.createElement("script");
      script.innerHTML =
      "(function(w,d,u,t,o,c){w['dmtrackingobjectname']=o;c=d.createElement(t);c.async=1;c.src=u;t=d.getElementsByTagName(t)[0];t.parentNode.insertBefore(c,t);w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments);};})(window, document, '//static.trackedweb.net/js/_dmptv4.js', 'script', 'dmPt');window.dmPt('create', 'DM-2085461064-02', 'makelovenotporn.tv');";
      document.head.appendChild(script);
      setDotLoaded(true);
      return () => {
        try {
          document.head.removeChild(script);
        } catch (e) {}
      };
    }, []);

    useEffect(() => {
      let settings = {
        api_base: "https://api-iam.intercom.io",
        app_id: data.intercomToken
      };
      if (data.user) {
        settings.user_id = data.user.UserID;
        settings.email = data.user.Email;
        settings.name = data.user.UserName;
        settings.created_at = data.user.CreatedAt;
      }
      window.Intercom("boot", settings);
    }, [data.intercomToken, data.user]);

    useEffect(() => {
      if (!dotLoaded || !data.user) return;

      dotIdentify(data.user.Email);
    }, [data.user, dotLoaded]);

    useEffect(() => {
      if (!dotLoaded || !data.user) return;
      dotTrack();

      if (cookies.cart) {
        dotCart({ cookies, setCookie, removeCookie });
      }
    }, [data.user, dotLoaded, location]);

    useEffect(() => {
      gaugesFunc(data.gaugesToken);
    }, []);

    useEffect(() => {
      window._gauges.push(["track"]);
      gtag.pageview(location.pathname, data.gTagID);
    }, [location]);
  }

  useEffect(() => {
    if (data.user && !data.masquerador)
    axios.get(`/events?path=${location.pathname}&params=${encodeURIComponent(location.search)}`);
  }, [location]);

  return (
    <html lang="en" className={clsx(!location.pathname.startsWith("/admin") && theme, "h-full")}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <link rel="icon" href={favicon} />
        <script type="text/javascript">var _gauges = _gauges || [];</script>
      </head>
      <body className={cn("relative font-inter", data.masquerador && "masquerading")}>
        <Outlet />
        <LiveReload />
        <Scripts />
        <ScrollRestoration />
      </body>
    </html>);

}

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en" className={clsx(Theme.DARK, "h-full")}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Oops...</title>
        <Links />
        <link rel="icon" href={favicon} />
        <script type="text/javascript">var _gauges = _gauges || [];</script>
      </head>
      <body className="relative font-inter bg-black">
        <Nav
          user={undefined}
          avatar={undefined}
          notificationCount={0}
          subCredits={0}
          bundleCredits={0}
          hideUser={true}
          reloadDocument={true} />

        <div className="grid grid-cols-1 sm:grid-cols-2 items-start justify-center gap-12 mt-36 md:mt-48 md:gap-16 mb-28 mx-12">
          <div className="flex items-center justify-center sm:justify-end">
            <img src={oops} alt="Oops" className="max-w-[250px] sm:max-w-[300px] md:max-w-[400px]" />
          </div>
          {error && isRouteErrorResponse(error) && error.status === 404 ?
          <div>
              <Typography className="text-center sm:text-left text-foreground text-pretty sm:mt-4 md:mt-8 text-[#f89185]">
                <h1 className="text-xl sm:text-2xl md:text-3xl text-primary">Oops! You're not meant to be here.</h1>
                <h3 className="text-sm sm:text-md md:text-lg text-[#f89185] mb-4">
                  Let's get you back to the real world stuff.
                </h3>
                <p className="text-sm sm:text-md md:text-lg">
                  <Link to="/videos" reloadDocument>
                    Explore real world sex videos
                  </Link>
                </p>
                <p className="text-sm sm:text-md md:text-lg">
                  <Link to="/makelovenotpornstars" reloadDocument>
                    Explore MakeLoveNotPornstars
                  </Link>
                </p>
                <p className="text-[#f89185]">
                  <b>Can't find what you're looking for?</b>
                  <br /> Email <Link to="mailto:support@mlnp.tv">support@mlnp.tv</Link> and we can help.
                </p>
              </Typography>
            </div> :
          <div>
              <Typography className="text-center sm:text-left text-foreground text-pretty sm:mt-4 md:mt-8 text-[#f89185]">
                <h1 className="text-xl sm:text-2xl md:text-3xl text-primary">Oops! Something went wrong.</h1>
                <h3 className="text-sm sm:text-md md:text-lg text-[#f89185] mb-4">
                  We're sorry for the inconvenience. Please try again later.
                </h3>
                <p className="text-[#f89185]">
                  Having trouble with a specific area of the site?
                  <br /> Email <Link to="mailto:support@mlnp.tv">support@mlnp.tv</Link> and we can help.
                </p>
              </Typography>
            </div>}

        </div>
        <Footer />
        <Scripts />
      </body>
    </html>);

}